var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "grid", fluid: "", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Grid System", link: "components/grid" }
      }),
      _c(
        "v-row",
        { staticClass: "text-center" },
        [
          _c("base-subheading", {
            staticClass: "pb-0 pl-3 mt-5",
            attrs: { subheading: "XS Grid", text: "Always Horizontal" }
          }),
          _vm._l(3, function(n) {
            return _c(
              "v-col",
              { key: "cols-" + n, staticClass: "pt-0", attrs: { cols: "4" } },
              [
                _c(
                  "v-card",
                  { staticClass: "mt-0" },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "red--text text--darken-4" },
                      [_vm._v("\n          col-4\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c("base-subheading", {
            staticClass: "pb-0 pl-3 mt-5",
            attrs: { subheading: "SM Grid", text: "Collapsed at 600px" }
          }),
          _vm._l(3, function(n) {
            return _c(
              "v-col",
              {
                key: "sm-" + n,
                staticClass: "pt-0",
                attrs: { cols: "12", sm: "4" }
              },
              [
                _c(
                  "v-card",
                  { staticClass: "mt-0" },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "red--text text--darken-4" },
                      [_vm._v("\n          col-sm-4\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c("base-subheading", {
            staticClass: "pb-0 pl-3 mt-5",
            attrs: { subheading: "MD Grid", text: "Collapsed at 960px" }
          }),
          _vm._l(3, function(n) {
            return _c(
              "v-col",
              {
                key: "md-" + n,
                staticClass: "pt-0",
                attrs: { cols: "12", md: "4" }
              },
              [
                _c(
                  "v-card",
                  { staticClass: "mt-0" },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "red--text text--darken-4" },
                      [_vm._v("\n          col-md-4\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c("base-subheading", {
            staticClass: "pb-0 pl-3 mt-5",
            attrs: { subheading: "LG Grid", text: "Collapsed at 1280px" }
          }),
          _vm._l(3, function(n) {
            return _c(
              "v-col",
              {
                key: "lg-" + n,
                staticClass: "pt-0",
                attrs: { cols: "12", lg: "4" }
              },
              [
                _c(
                  "v-card",
                  { staticClass: "mt-0" },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "red--text text--darken-4" },
                      [_vm._v("\n          col-lg-4\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c("base-subheading", {
            staticClass: "pb-0 pl-3 mt-5",
            attrs: { subheading: "XL Grid", text: "Collapsed at 1920px" }
          }),
          _vm._l(3, function(n) {
            return _c(
              "v-col",
              {
                key: "xl-" + n,
                staticClass: "pt-0",
                attrs: { cols: "12", xl: "4" }
              },
              [
                _c(
                  "v-card",
                  { staticClass: "mt-0" },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "red--text text--darken-4" },
                      [_vm._v("\n          col-xl-4\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c("base-subheading", {
            staticClass: "pb-0 pl-3 mt-5",
            attrs: {
              subheading: "Mixed Grid",
              text: "Showing different sizes on different screens"
            }
          }),
          _vm._l(4, function(n) {
            return _c(
              "v-col",
              {
                key: "mixed-" + n,
                staticClass: "pt-0",
                attrs: { sm: "6", lg: "3" }
              },
              [
                _c(
                  "v-card",
                  { staticClass: "mt-0" },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "red--text text--darken-4" },
                      [_vm._v("\n          col-xl-4\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "v-row",
        { staticClass: "text-center" },
        [
          _c("base-subheading", {
            staticClass: "pb-0 pl-3 mt-5",
            attrs: {
              subheading: "Offset Grid",
              text: "Adding some space when neede"
            }
          }),
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { md: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "red--text text--darken-4" },
                    [_vm._v("\n          col-md-3\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { md: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "red--text text--darken-4" },
                    [_vm._v("\n          col-md-4 ml-auto mr-auto\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "text-center" },
        _vm._l(2, function(n) {
          return _c(
            "v-col",
            {
              key: "offset-" + n,
              staticClass: "ml-auto mr-auto",
              attrs: { md: "4" }
            },
            [
              _c(
                "v-card",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "red--text text--darken-4" },
                    [_vm._v("\n          col-md-4 ml-auto mr-auto\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-row",
        { staticClass: "text-center mb-12" },
        [
          _c(
            "v-col",
            { staticClass: "ml-auto mr-auto", attrs: { md: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "red--text text--darken-4" },
                    [_vm._v("\n          col-md-6 ml-auto mr-auto\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("paragraph-heading", { staticClass: "text-center pb-0" }, [
            _c(
              "h4",
              {
                staticClass: "font-weight-light",
                staticStyle: { color: "rgb(60, 72, 88)", "font-size": "25px" }
              },
              [_vm._v("\n        Paragraphs\n      ")]
            )
          ]),
          _c(
            "v-col",
            { attrs: { cols: "12 pt-0" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("paragraph-heading", [
                                _vm._v("Some Title Here")
                              ]),
                              _c(
                                "v-card-text",
                                { staticClass: "font-weight-light" },
                                [
                                  _vm._v(
                                    '\n                One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. "What\'s happened to me?" he thought.\n              '
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("paragraph-heading", [
                                _vm._v("Another Title Here")
                              ]),
                              _c(
                                "v-card-text",
                                { staticClass: "font-weight-light" },
                                [
                                  _vm._v(
                                    '\n                One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. "What\'s happened to me?" he thought.\n              '
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("paragraph-heading", [
                                _vm._v("Some Title Here")
                              ]),
                              _c(
                                "v-card-text",
                                { staticClass: "font-weight-light" },
                                [
                                  _vm._v(
                                    "\n                One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment.\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("paragraph-heading", [
                                _vm._v("Another Title Here")
                              ]),
                              _c(
                                "v-card-text",
                                { staticClass: "font-weight-light" },
                                [
                                  _vm._v(
                                    "\n                One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment.\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("paragraph-heading", [
                                _vm._v("Another Title Here")
                              ]),
                              _c(
                                "v-card-text",
                                { staticClass: "font-weight-light" },
                                [
                                  _vm._v(
                                    "\n                One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment.\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c("paragraph-heading", [
                                _vm._v("Some Title Here")
                              ]),
                              _c(
                                "v-card-text",
                                { staticClass: "font-weight-light" },
                                [
                                  _vm._v(
                                    "\n                One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment.\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "8" } },
                            [
                              _c("paragraph-heading", [
                                _vm._v("Another Title Here")
                              ]),
                              _c(
                                "v-card-text",
                                { staticClass: "font-weight-light" },
                                [
                                  _vm._v(
                                    "\n                One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment.\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }